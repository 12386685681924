<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="操作功能">
            <el-select v-model="form.code" clearable>
              <el-option
                v-for="(item, index) in operateList"
                :key="index"
                :label="item.funName"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="表名">
            <el-input
              placeholder="请输入"
              v-model="form.tableName"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="执行类型">
            <el-select v-model="form.sqlCommand" clearable>
              <el-option
                v-for="(item, index) in sqlCommandList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="数据ID">
            <el-input
              placeholder="请输入"
              v-model="form.id"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="字段名称">
            <el-input
              placeholder="请输入"
              v-model="form.fieldName"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查询</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table
          ref="table"
          :data="tableData"
          v-loading="tableLoading"
          border
          stripe
          height="100%"
        >
          <el-table-column
            prop="webPageDesc"
            label="操作页面描述"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="desc"
            label="操作功能"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="itemType"
            label="执行类型"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="id"
            label="数据ID"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="fieldName"
            label="字段名称"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="fieldDesc"
            label="字段描述"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="newData"
            label="新值"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="oldData"
            label="旧值"
            align="center"
            min-width="150"
          />
          <el-table-column
            prop="operateUser"
            label="操作人"
            align="center"
            min-width="100"
          />
          <el-table-column
            prop="operateTime"
            label="操作时间"
            align="center"
            min-width="150"
          />
        </el-table>
      </template>
      <template #footer>
        <el-pagination
          class="mt10"
          :page-size="page.size"
          :current-page="page.current"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </template>
    </common-layout>
  </div>
</template>
<script>
import api from "@/api/auth/log";
export default {
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      operateList: [],
      sqlCommandList: [
        {
          label: "新增",
          value: "INSERT",
        },
        {
          label: "修改",
          value: "UPDATE",
        },
        {
          label: "删除",
          value: "DELETE",
        },
      ],
    };
  },
  mounted() {
    this.query();
    this.getOperateList();
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
      };
      this.tableLoading = true;
      api
        .query(params)
        .then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleQuery() {
      this.page.current = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.query();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.query();
    },
    getOperateList() {
      api.getOperateList().then((res) => {
        this.operateList = res.data;
      });
    },
  },
};
</script>
